import { Link } from "react-router-dom"

export const HeroSession = () => {
  return (
    <div className="hero-area pt-145 pb-75 rel z-1 mobpt-156">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="hero-content rmb-55 wow fadeInUp delay-0-2s">
              <span className="sub-title">Digitize 4 Life</span>
              <h1 style={{ fontSize: '2.8rem' }} >Your trusted Embroidery Digitizing Partner</h1>
              <p>With more than 25 years of experience in embroidery digitizing services, we stand as a USA-based top
                embroidery digitizing company that takes pride in delivering pixel perfect and top-notch services.
              </p>
              <ul className="list-style-one pt-10 wow fadeInUp delay-0-3s">
                <li>Embroidery Digitizing</li>
                <li>Custom Patches</li>
                <li>Vector Conversion</li>
              </ul>
              <div className="hero-btns pt-25 wow fadeInUp delay-0-4s">
                <Link aria-label="Go to Contact page" to="/contact" className="theme-btn">Talk With Us <i className="far fa-long-arrow-right" /></Link>
                <Link aria-label="Go to Registration page" to="https://login.digitize4life.com/register.asp" target="_blank" className="theme-btn style-three">Get Started <i className="far fa-long-arrow-right" /></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hero-images wow fadeInLeft delay-0-2s">
              {/* <img className="w-100" src="images/Home/digitizer-working-on-pc.avif" alt="Discover our craftsmanship with this intricate custom embroidery patch that captures every
detail of your design" title="Exquisite Custom Embroidery Patch" style={{borderRadius:"25px"}}/> */}

              <img className="w-100"
                src="digitizer-working-on-PC-241x201.avif"
                srcSet="
    images/Home/digitizer-working-on-PC-241x201.avif 241w,
    images/Home/digitizer-working-on-PC-418x350.avif 418w,
    images/Home/digitizer-working-on-PC-456x382.avif 456w
  "
                sizes="(max-width: 600px) 241px, (max-width: 900px) 418px, 456px"
                alt="Digitizer working on PC" width="456" height="382" style={{borderRadius:"25px", maxWidth: '100%', height: 'auto'}}
              />


              <img className="image-one wow fadeInRight delay-0-6s" src="images/Home/custom-patches-on-leather-jacket-19KB.avif" alt="Elevate your graphics with precision! Our vector conversion services ensure sharp, high-quality
images for all your design needs." title="Precision Vector Conversion" />
              <img className="image-two wow fadeInDown delay-0-8s" src="images/Home/custom-patch-on-cap1.avif" alt="Immerse yourself in our bespoke embroidery solutions. Each stitch is a testament to our
commitment to perfection" title="Bespoke Embroidery Solutions" style={{ width: "30%", height: "40%" }} />
              <div className="circle-shapes">
                <div className="shape-inner">
                  <span className="dot-one" />
                  <span className="dot-two" />
                  <span className="dot-three" />
                  <span className="dot-four" />
                  <span className="dot-five" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}